<template>
  <div class="container">
    <div class="fastener-group__header">
      <div class="slopePage__title">
        <b>{{ $t('message.details.grouping.title') }}</b>
        <tippy
          interactive
          placement="top"
          animation="shift-toward"
          arrow="false"
          :maxWidth="240"
          v-if="!groups.length"
        >
          <template #trigger>
            <div class="title-tooltip">
              <img src="@/assets/img/tooltip-icon.svg" alt="?" />
            </div>
          </template>
          <div class="simple-tooltip__text">{{ $t('message.details.grouping.tooltip') }}</div>
        </tippy>
      </div>
      <div class="toggler__box unprint" v-if="count > 1 && groups.length !== 0">
        <span class="showMore__text">
          {{ $t('message.details.grouping.showMore') }} ({{ count }})
        </span>
        <div class="fixtureCalculation__toggle">
          <div class="wrapper">
            <span class="checkbox-toggler">
              <span class="checkbox-track"></span>
              <input type="checkbox" v-model="computedIsShowMore" />
              <span class="toggler"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="fasteners-group" v-if="!!groups.length">
      <div
        class="fasteners-group__head"
        :class="{ 'fasteners-group__head--extend': isShowMore && count > 1 }"
      >
        <div class="title">
          <i18n path="message.details.grouping.table.number">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
        <div class="title">
          <i18n path="message.details.grouping.table.scheme">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
        <div class="title">
          <i18n path="message.details.grouping.table.thickness">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
        <div class="title">
          <i18n path="message.details.grouping.table.fasteners">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
        <div class="title">
          {{ $t('message.details.grouping.table.screw') }}
        </div>
        <div class="title">
          <i18n path="message.details.grouping.table.anchor">
            <template #break>
              <br />
            </template>
          </i18n>
        </div>
        <div class="title">{{ $t('message.details.grouping.table.count') }}</div>
        <div class="title" v-if="isShowMore && count > 1">
          <span>{{ count }} {{ $t('message.details.grouping.table.area') }} </span>
        </div>
      </div>
      <div
        class="fasteners-group__body"
        :class="{ 'fasteners-group__body--extend': isShowMore && count > 1 }"
      >
        <div class="item" v-for="item in filteredGroups" :key="item.id">
          <div class="item__text">
            {{ $t('message.details.grouping.table.row') }} {{ item.rowNumber }}
          </div>
          <div class="item__text">
            <b>{{ item.plateScheme }}</b>
          </div>
          <div class="item__text">{{ item.thickness }} {{ $t('message.units.mm') }}</div>
          <div class="item__text">{{ item.fastener }}</div>
          <div class="item__text">{{ item.screw }} {{ $t('message.units.mm') }}</div>
          <div class="item__text">{{ item.ank }}</div>
          <div class="item__text">
            <b>{{ item.mount }} {{ $t('message.units.pc') }}.</b>
          </div>
          <div class="item__text" v-if="isShowMore && count > 1">
            <span>{{ item.totalMount }} {{ $t('message.units.pc') }}.</span>
          </div>
        </div>
      </div>
      <table-show-remaining-button
        :is-show-remaining="isShowRemaining"
        v-if="isShowRemainingBtn"
        @toggleRemaining="isShowRemaining = !isShowRemaining"
      >
        {{ showRemainingText }}
      </table-show-remaining-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import TableShowRemainingButton from '@/components/dump/TableShowRemainingButton'
import { detailsTables } from '@/utils'
const displayCount = 18

export default {
  props: {
    count: Number
  },
  data() {
    return {
      isShowRemaining: false
    }
  },
  components: { TableShowRemainingButton },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS: 'UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS'
    })
  },
  watch: {},
  mounted() {},
  computed: {
    sortedGroups() {
      return [...this.groups].sort((a, b) => {
        return a.thickness - b.thickness
      })
    },
    filteredGroups() {
      return this.isShowRemaining ? this.groups : this.groups.slice(0, displayCount)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    result() {
      return this.$store.getters.getResult
    },
    groups() {
      return this.result.sections[this.slopeId][detailsTables.fasteners_group].data
    },
    isShowMore() {
      return this.result.sections[this.slopeId][detailsTables.fasteners_group].isExpanded
    },
    computedIsShowMore: {
      get() {
        return this.isShowMore
      },
      set(status) {
        this.UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS({
          sectorIndex: this.slopeId,
          table: detailsTables.fasteners_group,
          status
        })
      }
    },
    showRemainingText() {
      return this.isShowRemaining
        ? this.$t('message.details.grouping.hide')
        : `${this.$t('message.details.grouping.show')} ${this.groups.length}`
    },
    isShowRemainingBtn() {
      return this.groups.length > displayCount
    }
  }
}
</script>

<style lang="sass" scoped>
.fastener-group__header
  display: flex
  +media((align-items: (320: flex-start, 768: center)))
  +media((flex-direction: (320: column, 768: row)))
  +media((justify-content: (320: flex-start, 768: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(23), 992: rem(68) rem(-15) rem(34))))
  +media((padding: (320: 0 rem(25), 992: 0)))
  .slopePage
    &__title
      padding: 0
      +media((margin-bottom: (320: rem(15), 768: 0)))
      +media((font-size: (320: rem(18), 768: rem(24))))
      display: flex
      align-items: center
      .title-tooltip
        margin-left: rem(10)
        display: flex
        align-items: center
.detailsPage
  .mt-34
    margin-top: rem(34)
  .slopePage
    &__title
      +media((font-size: (320: rem(18), 992: rem(24))))
      +media((line-height: (320: rem(26), 992: rem(29))))
  .toggler__box
    display: flex
    flex-direction: row
    align-items: center
    .showMore
      &__text
        font-style: normal
        font-weight: 500
        padding-right: rem(20)
        text-align: left
        +media((font-size: (320: rem(12), 992: rem(14))))
        +media((line-height: (320: rem(20), 992: rem(22))))
    .checkbox-toggler
      .checkbox-track
        box-shadow: inset 0 rem(2) rem(4) rgba(153, 153, 153, 0.5)
  .fasteners-group
    display: grid
    margin: 0 rem(-15)
    overflow-y: auto
    border-radius: rem(4) rem(4) 0 0
    color: $black
    &__head
      display: grid
      +media((padding: (320: 0 rem(24), 768: 0)))
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      background: $semi-white
      $t-xl-extend: rem(20) 4fr 4fr 7fr 5fr 5fr 6fr 4fr 4fr
      $t-xl: rem(20) 4fr 4fr 7fr 5fr 5fr 6fr 4fr
      $t-s: rem(60) rem(60) repeat(4, rem(100)) rem(80)
      $t-s-extend: rem(60) rem(60) repeat(4, rem(100)) rem(80) rem(80)
      +media((grid-template-columns: (320: $t-s, 768: $t-xl)))
      .title
        min-height: rem(60)
        display: flex
        flex-direction: column
        justify-content: center
        @extend .common__title-result
        text-align: left
        &:first-child
          +media((grid-column-start: (768: 2)))
      &--extend
        +media((grid-template-columns: (320: $t-s-extend, 768: $t-xl-extend)))
    &__body
      width: 100%
      display: grid
      margin-top: rem(1)
      grid-template-columns: 1fr
      grid-template-rows: 1fr
      grid-row-gap: rem(1)
      .item
        text-align: left
        background: $white
        align-items: center
        +media((padding: (320: 0 rem(25), 768: 0)))
        column-gap: calc(100% / 48)
        +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
        $t-xl: rem(20) 4fr 4fr 7fr 5fr 5fr 6fr 4fr
        $t-s: rem(60) rem(60) repeat(4, rem(100)) rem(80)
        +media((grid-template-columns: (320: $t-s, 768: $t-xl)))
        width: 100%
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(17)
        grid-row-gap: rem(1)
        min-height: rem(51)
        display: grid
        &__text
          span
            color: rgba($black, .5)
          &:first-child
            +media((grid-column-start: (768: 2)))
      &--extend
        .item
          $t-xl-extend: rem(20) 4fr 4fr 7fr 5fr 5fr 6fr 4fr 4fr
          $t-s-extend: rem(60) rem(60) repeat(4, rem(100)) rem(80) rem(80)
          +media((grid-template-columns: (320: $t-s-extend, 768: $t-xl-extend)))
</style>

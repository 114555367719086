<template>
  <section class="detailsPage" v-if="!isPageDisAllowed">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="1"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="fullPage"
    ></loading>
    <div class="container">
      <div class="row unprint">
        <div class="detailsPage__nav">
          <router-link class="detailsPage__nav-btn" :to="{ name: 'Result' }">
            <svg
              width="15"
              height="10"
              viewBox="0 0 15 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99412 5H14M4.82353 9L1 5L4.82353 1"
                stroke="var(--main-color)"
                stroke-width="2"
                stroke-miterlimit="22.9256"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            {{ $t('message.details.breadcrumb.total') }}
          </router-link>
          <div class="detailsPage__nav-label">
            {{ $t('message.details.breadcrumb.computation') }} {{ slopeFromState.name }}
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between detailsPage__title-box">
        <div class="detailsPage__title">{{ slopeFromState.name }}. {{ slopeTypeName }}</div>
        <div class="d-flex align-items-center flex-row unprint">
          <button
            @click="isShowDeleteConfirmation = !isShowDeleteConfirmation"
            class="delete__btn"
            v-if="this.allSlopes.length !== 1"
          >
            <svg
              width="19"
              height="25"
              viewBox="0 0 19 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.91667L18 5.29333M5.73105 3.60667L7.89615 1L12.1461 1.61333L13.349
            4.60333M12.467 12.1933L12.2264 19.5533M6.61321 12.1933L6.85377 19.63M1.56128
            7.74667H17.4386L16.6368 21.7C16.5566 22.9267 15.5141 24 14.2311 24H4.76883C3.40562 24
            2.44336 22.9267 2.36317 21.7L1.56128 7.74667Z"
                stroke="#999999"
                stroke-width="2"
                stroke-miterlimit="22.9256"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
          <a class="detailsPage__edit" @click="edit()">
            <img src="@/assets/img/cog-white.svg" alt="cog" />
            {{ $t('message.details.system.edit') }}
          </a>
        </div>
      </div>
    </div>
    <details-header :currentResult="currentResult" />
    <DetailsPlateLayout
      :materialAngle="currentMaterial.angleValue"
      :fixtureCalculationThickness="slopeFromState.fixtureCalculation.thickness"
      :hKr="currentMaterial.params[0].hKr"
      :qKr="currentMaterial.params[0].qKr"
      :y1="currentMaterial.params[0].y1"
      :y2="currentMaterial.params[0].y2"
      :backEndRowsCount="rowsCount"
      ref="plateLayout"
    />
    <DetailsMaterialsComputation :count="slopeFromState.slopeCount" @requestNewResults="getTotalResults"/>
    <DetailsFastener :count="slopeFromState.slopeCount" />
    <DetailsGrouping :count="slopeFromState.slopeCount" />
    <addons :items="currentResult.albums" v-if="currentResult.albums.length"/>
    <CalculationNotification />
    <ResultNav is-details />
    <Prefooter @savePdf="savePdf" @saveXls="saveXls" />
    <transition name="fading">
      <DeleteConfirmation
        v-if="isShowDeleteConfirmation"
        @confirm="deleteSlope"
        @cancel="isShowDeleteConfirmation = !isShowDeleteConfirmation"
      />
    </transition>
  </section>
</template>

<script>
import {
  getCurrentBasement,
  slopeTypes,
  detailsTables,
  getPdfTableTitle
} from '@/utils'
import { getXlsSector, getPdfSector } from '@/api'
import { defaultSvgParams } from '@/utils/drawing'
import { saveAs } from 'file-saver'
import { mapActions, mapGetters, mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import { isSafari } from '@/utils/utils'
import Prefooter from '@/components/Prefooter.vue'
import DetailsPlateLayout from '@/components/details/DetailsPlateLayout.vue'
import DetailsMaterialsComputation from '@/components/details/DetailsMaterialsComputation.vue'
import DetailsFastener from '@/components/details/DetailsFastener.vue'
import DetailsGrouping from '@/components/details/DetailsGrouping.vue'
import ResultNav from '@/components/result/ResultNav.vue'
import DeleteConfirmation from '@/components/DeleteConfirmation.vue'
import DetailsHeader from '@/components/details/DetailsHeader'
import Addons from '@/components/dump/addons'
import { stepNumbers } from '@/utils/stepNumbers'
import { region } from '@/utils/makeInitialRequest'
import UpdateStepMixin from '@/components/mixin/UpdateStepMixin'
import CalculationNotification from '@/components/dump/CalculationNotification.vue'

export default {
  mixins: [UpdateStepMixin],
  props: {
    slope: Object
  },
  data() {
    return {
      fullPage: true,
      isLoading: false,
      isShowDeleteConfirmation: false
    }
  },
  components: {
    Addons,
    DetailsHeader,
    Prefooter,
    DetailsPlateLayout,
    DetailsMaterialsComputation,
    DetailsFastener,
    DetailsGrouping,
    ResultNav,
    DeleteConfirmation,
    Loading,
    CalculationNotification
  },
  methods: {
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults',
      getTotalResults: 'getTotalResults',
      resetUnit: 'resetUnit'
    }),
    deleteSlope() {
      this.resetSaveCalculationResult()
      if (this.allSlopes.length - 1 === parseInt(this.slopeId, 10)) {
        this.$store.commit('deleteSlope', this.slopeId)
        this.$router.push({ name: 'Result' })
        this.$root.onBodyScroll()
      } else {
        this.$store.commit('deleteSlope', this.slopeId)
        this.$root.onBodyScroll()
        this.$router.push({ name: 'Result' })
      }
    },
    savePdf() {
      this.isLoading = true
      const {
        $refs,
        slopeFromState,
        slopeTypeName,
        square,
        singleVolume,
        totalVolume,
        rowsCount,
        currentMaterial,
        fastenerCalculation,
        userInfo,
        groups,
        fasteners,
        computation,
        currentAlbums,
        pdfFixtureCalculation,
        currentResult
      } = this
      const image = $refs.plateLayout.$refs.svgComponent.$refs.figure.outerHTML
      const postDataHeader = {
        title: `${slopeFromState.name}. ${slopeTypeName ?? ''}`,
        slopeCount: slopeFromState.slopeCount,
        square: square,
        singleVolume: singleVolume,
        totalVolume: totalVolume,
        rowsCount: rowsCount,
        material: `${currentMaterial.name} ${currentMaterial.angle}`,
        sizes: `${slopeFromState.materialSize.width}x${slopeFromState.materialSize.height} мм`,
        fixtureCalculationNeed: fastenerCalculation,
        fixtureCalculationType: pdfFixtureCalculation.type,
        fixtureCalculationThickness: pdfFixtureCalculation.thickness,
        maxHeightSlope: currentResult.maxHeightSlope,
        wedgeSquare: currentResult.wedgeSquare
      }
      if (this.isContr) {
        postDataHeader.roofOptionsBasement = this.roofOptionsBasement
      }
      getPdfSector(
        image,
        postDataHeader,
        groups,
        fasteners,
        computation,
        currentAlbums,
        `${slopeFromState.fixtureCalculation.need}`,
        this.pdfMaterialsTitle,
        this.pdfFastenersTitle,
        this.$i18n.locale,
        region,
        userInfo?.result?.id,
        this.publicCalculationLink
      )
        .then(response => {
          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          this.isLoading = false
          if (this.isSafari) {
            const fileName = this.$t('message.details.save.fileName')
            saveAs(file, fileName)
          } else {
            window.open(fileURL)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveXls() {
      this.isLoading = true
      const fileName = this.$t('message.details.save.fileName')
      const { slopeFromState, baseTypes, fastenerCalculation, userInfo } = this
      let user = null
      if (this.publicCalculationLink) {
        user = {}
        user.link = this.publicCalculationLink
      }
      const header = {
        title: `${slopeFromState.name}. ${this.slopeTypeName ?? ''}`,
        count: slopeFromState.slopeCount,
        fastenerCalculation,
        thickness: slopeFromState.fixtureCalculation.need
          ? slopeFromState.fixtureCalculation.thickness
          : null,
        basement: getCurrentBasement(
          slopeFromState.fixtureCalculation.need,
          baseTypes,
          slopeFromState.fixtureCalculation.type
        )
      }
      getXlsSector(
        this.section,
        slopeFromState.fixtureCalculation.need ? slopeFromState.fixtureCalculation.thickness : null,
        'section',
        user,
        header,
        this.$i18n.locale,
        region,
        userInfo.result?.id
      ).then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        this.isLoading = false
        saveAs(file, fileName)
      })
    },
    edit() {
      this.resetSaveCalculationResult()
      this.$router.push(`/wizard/${this.slopeId}/`)
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
      this.resetAllUnits()
    },
    resetAllUnits() {
      this.resetUnit({ index: this.slopeId })
      this.resetUnit({ index: null })
    }
  },
  mounted() {
    if (this.isPageDisAllowed) {
      this.$router.push('/')
    } else {
      this.updateStep(stepNumbers.details)
    }
  },
  watch: {
    isShowDeleteConfirmation() {
      this.isShowDeleteConfirmation === true
        ? this.$root.offBodyScroll()
        : this.$root.onBodyScroll()
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      allMaterials: state => state.materials,
      result: state => state.result,
      baseTypes: state => state.baseTypes
    }),
    ...mapGetters({
      publicCalculationLink: 'publicCalculationLink'
    }),
    isPageDisAllowed() {
      return this.allSlopes[0].material === ''
    },
    roofOptionsBasement() {
      if (this.isContr) {
        const currentBasement = this.slopeFromState.roofOptions?.base
        if (currentBasement === 'default') {
          return this.$t('message.sectorRoof.roofBase.default')
        } else {
          const materialId = this.slopeFromState.roofOptions?.material
          return this.allMaterials.find(material => material.id === materialId).name
        }
      } else {
        return null
      }
    },
    isContr() {
      return this.slopeFromState.slopeType === slopeTypes.contrSlope
    },
    isSafari() {
      return isSafari()
    },
    currentMaterial() {
      return this.allMaterials.find(m => m.id === this.slopeFromState.material)
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    defaultSvgParams: () => defaultSvgParams,
    currentResult() {
      return this.result.sections[this.slopeId]
    },
    section() {
      return this.result.sections[this.slopeId]
    },
    rowsCount() {
      return this.section.rowsCount
    },
    singleVolume() {
      return this.section.volumeSingle
    },
    totalVolume() {
      return this.section.volumeTotal
    },
    square() {
      return this.section.square
    },
    groups() {
      return this.section.fasteners_group
    },
    fasteners() {
      return this.section.fasteners
    },
    computation() {
      return this.result.sections[this.slopeId].computation
    },
    masonryType() {
      return this.currentMaterial.params[0].type
    },
    fastenerCalculation() {
      return this.slopeFromState.fixtureCalculation.need
        ? this.$t('message.details.fastener.table.fastenersCalc.active')
        : this.$t('message.details.fastener.table.fastenersCalc.inactive')
    },
    slopeTypeName() {
      return this.$t('message.slopeTypesName')[this.slopeFromState.slopeType]
    },
    currentAlbums() {
      return this.section.albums
    },
    pdfFixtureCalculation() {
      const isFixtureCalculationNeed = this.slopeFromState.fixtureCalculation.need
      return {
        type: isFixtureCalculationNeed
          ? this.baseTypes.find(b => b.id === this.slopeFromState.fixtureCalculation.type).name
          : this.$t('message.general.noData'),
        thickness: isFixtureCalculationNeed
          ? `${this.slopeFromState.fixtureCalculation.thickness} ${this.$t('message.units.mm')}`
          : this.$t('message.general.noData')
      }
    },
    pdfMaterialsTitle() {
      const translation = this.$t('message.result.head.words')
      const { slopeCount } = this.slopeFromState
      const defaultTitle = this.$t('message.api.getPdf.titles.materials')
      const isTableToggled = this.currentResult[detailsTables.computation].isExpanded
      return getPdfTableTitle(translation, slopeCount, defaultTitle, isTableToggled)
    },
    pdfFastenersTitle() {
      const translation = this.$t('message.result.head.words')
      const { slopeCount } = this.slopeFromState
      const defaultTitle = this.$t('message.api.getPdf.titles.fasteners')
      const isTableToggled = this.currentResult[detailsTables.fasteners].isExpanded
      return getPdfTableTitle(translation, slopeCount, defaultTitle, isTableToggled)
    }
  }
}
</script>

<style lang="sass" scoped>
.detailsPage
  color: $black
  .delete
    &__btn
      margin-right: rem(6)
  &__nav
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: wrap
    +media((padding: (320: rem(22) rem(24) rem(27) rem(24), 992: rem(24) 0 rem(17) 0)))
    &-btn
      color: $main
      border: rem(1) solid $main
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      box-shadow: none
      background: none
      outline: none
      transition: .5s
      font-style: normal
      font-weight: 600
      font-size: rem(12)
      line-height: rem(24)
      font-family: $main-font
      height: rem(34)
      cursor: pointer
      text-decoration: none
      margin-right: rem(4)
      margin-top: rem(10)
      +media((min-width: (320: rem(145), 768: rem(158))))
      svg
        margin-right: rem(11)
        path
          transition: .5s
          stroke: $main
      &:hover
        background: $main
        color: $white
        svg
          path
            stroke: $white
    &-label
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(24)
      color: $light_black
      background: $semi-white
      border-radius: rem(4)
      min-height: rem(34)
      text-align: left
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      flex-wrap: wrap
      margin-top: rem(10)
      padding: 0 rem(16)
      +media((min-width: (320: rem(131), 768: rem(142))))
  &__title
    font-style: normal
    font-weight: 500
    text-align: left
    +media((font-size: (320: rem(20), 768: rem(28))))
    +media((line-height: (320: rem(26), 768: rem(34))))
    +media((padding-bottom: (320: rem(27), 576: 0)))
    &-box
      +media((padding-right: (320: rem(24), 992: 0)))
      +media((padding-left: (320: rem(24), 992: 0)))
  &__edit
    background: $main
    border-radius: rem(4)
    transition: .5s
    color: $white
    font-style: normal
    font-weight: bold
    font-size: rem(14)
    line-height: rem(17)
    text-decoration: none
    display: flex
    flex-direction: row
    align-items: center
    +media((padding: (320: rem(15) rem(15), 360: rem(15) rem(23) rem(15) rem(20),768: rem(15) rem(27) rem(15) rem(22))))
    img
      +media((margin-right: (320: rem(10), 992: rem(20))))
      transition: .5s
    &:hover
      background: $main
      opacity: $hover-opacity
      img
        transform: rotate(-180deg)
  &__pane
    +media((margin-top: (320: rem(32), 768: rem(43))))
    +media((padding-bottom: (320: rem(24), 768: rem(34))))
  &__info
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    +media((padding-left: (320: rem(9), 992: rem(25))))
    +media((padding-bottom: (320: rem(14), 768: rem(16))))
    &-only
      +media((flex-direction: (320: column, 480: row, 768: column)))
      +media((align-items: (320: flex-start, 480: center, 768: flex-start)))
      .detailsPage__info-text--data
        +media((width: (320: auto, 480: rem(200), 768: auto)))
    &.fixture
      +media((padding-top: (320: rem(16), 768: rem(34))))
      .detailsPage__info-text--data
        +media((min-width: (320: unset, 480: rem(200))))
        +media((padding-right: (320: rem(9), 768: 0)))
    &-right
      .detailsPage__info-text--data
        min-width: rem(200)
    &--doubleRow
      +media((flex-direction: (320: column, 480: row)))
      +media((align-items: (320: flex-start, 480: center)))
      +media((padding-top: (320: rem(10), 480: 0)))
      +media((padding-bottom: (320: rem(10), 480: rem(14))))
      .detailsPage__info-text
        +media((padding-bottom: (320: rem(9), 480: 0)))
      .detailsPage__info-text--data
        +media((width: (320: auto, 480: rem(200))))
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: rem(18)
      text-align: left
      &--data
        text-align: left
        +media((width: (320: rem(80), 480: rem(200), 768: rem(96))))
        .toggler
          position: relative
          width: rem(42)
          .track
            width: rem(42)
            height: rem(14)
            background: $ec
            box-shadow: inset 0 rem(2) rem(4) $tn_gray
            border-radius: rem(12)
          &__btn
            background: $white
            box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.16)
            height: rem(24)
            width: rem(24)
            border-radius: 50%
            transition: .5s
            position: absolute
            top: 50%
            transform: translateY(-50%)
            left: rem(-6)
            right: auto
          &.active
            .toggler__btn
              background: $main
              right: rem(-6)
              left: auto
</style>

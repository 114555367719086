<template>
  <div class="printTable__wrapper">
    <div class="printTable">
      <div class="printTable__item">
        <div class="printTable__item-number">№ Ряда</div>
        <div class="printTable__item-plates">Схема плит</div>
        <div class="printTable__item-sizes" v-if="slopeFromState.fixtureCalculation.need">
          Крепеж
        </div>
      </div>
      <div class="printTable__item" v-for="i in backEndRowsCount" :key="i">
        <div class="printTable__item-number">Ряд {{ i }}</div>
        <div class="printTable__item-plates">
          <span class="text__info">
            {{ getCurrentPlateScheme(i) }}
          </span>
        </div>
        <div class="printTable__item-sizes" v-if="slopeFromState.fixtureCalculation.need">
          {{ getCurrentRowHeight(i).min }} - {{ getCurrentRowHeight(i).max }} мм
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    materialAngle: Number,
    fixtureCalculationThickness: Number,
    hKr: Number,
    qKr: Number,
    y1: Number,
    y2: Number,
    backEndRowsCount: Number
  },
  data() {
    return {
      rowInfo: []
    }
  },
  components: {},
  watch: {},
  methods: {
    getCurrentRowHeight(index) {
      return this.result.sections[this.slopeId].rowsHeight[index] ?? 0
    },
    getCurrentPlateScheme(index) {
      return this.result.sections[this.slopeId].plateLayout[index]
    }
    // myRound(val) {
    //   return Math.round(val / 10) * 10
    // },
    // getPlate(i) {
    //   if (this.masonryType === 'ABC') {
    //     if (this.slopeFromState.slopeLayersCount > 1) {
    //       return i % 2 === 0
    //         ? `${this.slopeFromState.slopeLayersCount}B`
    //         : `${this.slopeFromState.slopeLayersCount}A`
    //     }
    //     return i % 2 === 0 ? 'B' : 'A'
    //   } else if (this.masonryType === 'JKD') {
    //     if (this.slopeFromState.slopeLayersCount > 1) {
    //       return i % 2 === 0
    //         ? `${this.slopeFromState.slopeLayersCount}K`
    //         : `${this.slopeFromState.slopeLayersCount}J`
    //     }
    //     return i % 2 === 0 ? 'K' : 'J'
    //   } else {
    //     if (this.slopeFromState.slopeLayersCount > 1) {
    //       if (this.masonryType === 'JD') {
    //         if ((i - 1) * this.slopeFromState.slopeLayersCount === 0) {
    //           return `${this.slopeFromState.slopeLayersCount}J`
    //         }
    //         return `${this.slopeFromState.slopeLayersCount}J + ${
    //           (i - 1) * this.slopeFromState.slopeLayersCount
    //         }D`
    //       } else {
    //         if ((i - 1) * this.slopeFromState.slopeLayersCount === 0) {
    //           return `${this.slopeFromState.slopeLayersCount}M`
    //         }
    //         return `${this.slopeFromState.slopeLayersCount}M + ${
    //           (i - 1) * this.slopeFromState.slopeLayersCount
    //         }D`
    //       }
    //     } else {
    //       if ((i - 1) * this.slopeFromState.slopeLayersCount === 0) {
    //         return this.masonryType === 'JD' ? `J` : `M`
    //       } else if ((i - 1) * this.slopeFromState.slopeLayersCount === 1) {
    //         return this.masonryType === 'JD' ? 'J + D' : 'M + D'
    //       } else {
    //         return this.masonryType === 'JD'
    //           ? `J + ${(i - 1) * this.slopeFromState.slopeLayersCount}D`
    //           : `M + ${(i - 1) * this.slopeFromState.slopeLayersCount}D`
    //       }
    //     }
    //   }
    // },
    // getAdditionalPlate(i) {
    //   if (this.masonryType === 'ABC') {
    //     if (Math.floor((i + 1) / 2) - 1 > 0) {
    //       if (Math.floor(((i + 1) / 2 - 1) * this.slopeFromState.slopeLayersCount) > 1) {
    //         return `+ ${(Math.floor((i + 1) / 2) - 1) * this.slopeFromState.slopeLayersCount}C`
    //       }
    //       return '+ C'
    //     }
    //     return ''
    //   } else if (this.masonryType === 'JKD') {
    //     if (Math.floor((i + 1) / 2) - 1 > 0) {
    //       if (Math.floor(((i + 1) / 2 - 1) * this.slopeFromState.slopeLayersCount) > 1) {
    //         return `+ ${(Math.floor((i + 1) / 2) - 1) * this.slopeFromState.slopeLayersCount}D`
    //       }
    //       return '+ D'
    //     }
    //     return ''
    //   }
    //   return ''
    // },
    // getFirstFastener(i) {
    //   return this.myRound(
    //     Math.floor(
    //       ((i - 1) * this.slopeFromState.materialSize.width + this.hKr + this.d1) *
    //         Math.tan(this.slopeFromState.slopeLayersCount * Math.atan(this.materialAngle / 100)) +
    //         this.thickness
    //     )
    //   )
    // },
    // getSecondFastener(i) {
    //   if (i < this.rowsCount) {
    //     return this.myRound(
    //       Math.floor(
    //         ((i - 1) * this.slopeFromState.materialSize.width +
    //           this.slopeFromState.materialSize.width -
    //           this.hKr +
    //           this.d1) *
    //           Math.tan(this.slopeFromState.slopeLayersCount * Math.atan(this.materialAngle / 100)) +
    //           this.thickness
    //       )
    //     )
    //   }
    //   return this.maxFastener
    //   // return this.myRound((this.slopeFromState.slopeParams[0].width * 1000 - this.hKr + this.d1)
    //   //   * (Math.tan(this.slopeFromState.slopeLayersCount * Math.atan(this.materialAngle / 100))
    //   //   ) + ((this.slopeFromState.fixtureCalculation.need) ? this.thickness : 0));
    // }
  },
  computed: {
    ...mapState({
      result: state => state.result
    }),
    d1() {
      return this.y1 * (this.slopeFromState.materialSize.width / (this.y2 - this.y1))
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    thickness() {
      return this.slopeFromState.fixtureCalculation.need ? this.fixtureCalculationThickness : 0
    },
    rowsCount() {
      return this.backEndRowsCount
    },
    result() {
      return this.$store.getters.getResult
    },
    section() {
      return this.result.sections[this.slopeId]
    },
    plateHeight() {
      return this.slopeFromState.materialSize.height
    },
    plateWidth() {
      return this.slopeFromState.materialSize.width
    },
    maxFastener() {
      return Math.max.apply(
        null,
        this.section.fasteners_group.data.map(a => a.thickness)
      )
    }
  },
  mounted() {
    // for (let i = 1; i <= this.rowsCount; i += 1) {
    //   this.rowInfo.push({
    //     name: `Ряд ${i}`,
    //     plates: `${this.getPlate(i)} ${this.getAdditionalPlate(i)}`,
    //     fastener: `${this.getFirstFastener(i)} - ${this.getSecondFastener(i)} мм`
    //   })
    // }
    // this.$store.commit('updateRowsToPdf', this.rowInfo)
    // console.log(this.rowInfo);
  }
}
</script>

<style lang="sass">
.printTable
  background: $white
  display: flex
  flex-direction: row
  flex-wrap: wrap
  &__item
    color: $black
    width: 100%
    border: rem(1) solid #eee
    flex-direction: row
    align-items: center
    font-size: rem(12)
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    &-number, &-plates, &-sizes
      padding: rem(5)
      font-weight: 700
      text-align: left
    &-number
      min-width: rem(52)
    &-plates
      color: $main
      min-width: rem(62)
    &-sizes
      font-weight: 500
  &__wrapper
    display: none
</style>

<template>
  <div class="details-header">
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.slopeCount') }}</div>
      <div class="val">{{ slopeFromState.slopeCount }}</div>
    </div>
    <div class="details-header__item" v-if="isContr">
      <div class="title">{{ $t('message.details.header.mainSlope') }}</div>
      <div class="val">{{ slopeAngle }}{{ $t('message.units.percent') }}</div>
    </div>
    <div class="details-header__item" v-if="isContr">
      <div class="title">{{ $t('message.details.header.maxHeight') }}</div>
      <div class="val">{{ currentResult.maxHeightSlope }} {{ $t('message.units.mm') }}</div>
    </div>
    <div class="details-header__item">
      <div class="title">
        <div class="text">{{ $t('message.details.header.factSquare') }}</div>
        <tippy interactive placement="top" animation="shift-toward" arrow="false" :maxWidth="240">
          <template #trigger>
            <span class="title-tooltip" v-html="getIcon('tooltip')"></span>
          </template>
          <div class="simple-tooltip__text">{{ $t('message.details.header.glueCount') }}</div>
        </tippy>
      </div>
      <div class="val">{{ currentResult.wedgeSquare }} {{ $t('message.units.m2') }}</div>
    </div>
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.fasteners') }}</div>
      <div class="val">{{ currentResult.fastenerAverage }} {{ $t('message.units.pc') }}.</div>
    </div>
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.material') }}</div>
      <div class="val">
        {{ currentMaterial.name }}
        <img src="@/assets/img/angle.svg" alt="angle" />
        {{ currentMaterial.angle }} %
      </div>
    </div>
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.plateSize') }}</div>
      <div class="val">
        {{ slopeFromState.materialSize.width }}x{{ slopeFromState.materialSize.height }}
        {{ $t('message.units.mm') }}
      </div>
    </div>
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.isolationCount') }}</div>
      <div class="val">{{ slopeFromState.slopeLayersCount }}</div>
    </div>
    <div class="details-header__item">
      <div class="title">{{ $t('message.details.header.fastenersCalc') }}</div>
      <div class="val">{{ fastenerCalculation }}</div>
    </div>
    <div class="details-header__item" v-if="slopeFromState.fixtureCalculation.need">
      <div class="title">{{ $t('message.details.header.roofBase') }}</div>
      <div class="val">{{ currentBasement }}</div>
    </div>
    <div class="details-header__item" v-if="slopeFromState.fixtureCalculation.need">
      <div class="title">{{ $t('message.details.header.mainIsolation') }}</div>
      <div class="val">
        {{ slopeFromState.fixtureCalculation.thickness }} {{ $t('message.units.mm') }}
      </div>
    </div>
  </div>
</template>

<script>
import { slopeTypes, getCurrentBasement } from '@/utils'
import { mapState } from 'vuex'
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    currentResult: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  name: 'DetailsHeader',
  computed: {
    ...mapState({
      allMaterials: state => state.materials,
      baseTypes: state => state.baseTypes
    }),
    currentMaterial() {
      return this.allMaterials.find(m => m.id === this.slopeFromState.material)
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    fastenerCalculation() {
      return this.slopeFromState.fixtureCalculation.need
        ? this.$t('message.details.fastener.table.fastenersCalc.active')
        : this.$t('message.details.fastener.table.fastenersCalc.inactive')
    },
    currentBasement() {
      return getCurrentBasement(
        this.slopeFromState.fixtureCalculation.need,
        this.baseTypes,
        this.slopeFromState.fixtureCalculation.type
      )
    },
    isContr() {
      return this.slopeFromState.slopeType === slopeTypes.contrSlope
    },
    slopeAngle() {
      const { slopeFromState, allMaterials } = this
      const [paramsKit] = slopeFromState.slopeParams
      if (slopeFromState.roofOptions.base === 'custom') {
        const usedMaterial = allMaterials.find(m => m.id === slopeFromState.roofOptions.material)
        return `${usedMaterial.name} ${usedMaterial.angle}`
      }
      return paramsKit.roofSlope
    }
  }
}
</script>

<style scoped lang="sass">
.details-header
  background: $white
  max-width: rem(960)
  margin: rem(51) auto rem(68)
  border-radius: rem(4)
  color: $black
  +media((padding: (320: rem(34) rem(25), 992: rem(40))))
  display: grid
  row-gap: rem(24)
  &__item
    display: grid
    text-align: left
    +media((grid-template-columns: (320: 1fr, 576: 20fr 23fr)))
    column-gap: calc(100% / 44)
    +media((row-gap: (320: rem(12), 576: 0)))
    .title, .val
      font-style: normal
      font-size: rem(14)
      line-height: 100%
    .title
      font-weight: normal
      display: flex
      align-items: center
      .text
        margin-right: rem(10)
    .val
      font-weight: bold
</style>

<template>
  <div class="container">
    <div class="computation-header">
      <div class="slopePage__title">
        <b>{{ $t('message.details.materialsComputation.title') }}</b>
      </div>
      <Toggler
        :switch-items="units"
        :selected="unit || ''"
        @updateToggledValue="updateUnit($event)"
      />
      <div class="toggler__box unprint" v-if="count > 1">
        <span class="showMore__text">
          {{ $t('message.details.materialsComputation.count') }} ({{ count }})
        </span>
        <div class="fixtureCalculation__toggle">
          <div class="wrapper">
            <span class="checkbox-toggler">
              <span class="checkbox-track"></span>
              <input type="checkbox" v-model="computedIsShowMore" />
              <span class="toggler"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row computation">
      <div class="computation__head">
        <div class="computation__head-title">
          {{ $t('message.details.materialsComputation.table.material') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.details.materialsComputation.table.size') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.details.materialsComputation.table.ekn') }}
        </div>
        <div class="computation__head-title">
          {{ $t('message.details.materialsComputation.table.count') }} <br />
          <span>{{ $t('message.details.materialsComputation.table.computation') }}</span>
          <span v-if="isShowMore && count > 1"> {{ count }} {{ word }} </span>
        </div>
        <div class="computation__head-title">
          {{ $t('message.details.materialsComputation.table.count') }} <br />
          <span>{{ $t('message.details.materialsComputation.table.package') }}</span>
          <span v-if="isShowMore && count > 1"> {{ count }} {{ word }} </span>
        </div>
        <div class="computation__head-title unprint">
          {{ $t('message.details.materialsComputation.table.scheme') }}
        </div>
      </div>
      <div class="computation__body">
        <div v-for="item in computation" class="computation__item" :key="item.id">
          <div class="computation__item-img">
            <img :src="currentMaterial.src" :alt="currentMaterial.name" />
          </div>
          <div class="computation__item-material">
            <div class="name">
              {{ currentMaterial.name }}
            </div>
            <div class="angle">
              <img src="@/assets/img/angle.svg" alt="angle" />
              <b> {{ currentMaterial.angle }}% </b>
            </div>
          </div>
          <div class="computation__item-plate">
            <div class="size">{{ item.size }}</div>
            <div class="plate">
              <b
                >{{ $t('message.details.materialsComputation.table.plate') }}
                {{ item.plateType }}</b
              >
            </div>
            <div class="info" v-if="item.hasOwnProperty('plateInfo')">{{ item.plateInfo }}</div>
          </div>
          <div class="computation__item-ekn">{{ item.sku_hidden ? '' : item.sku }}</div>
          <div class="computation__item-count">
            <b>{{ item.count }} {{ item.countUnit }}</b> <br />
            <span v-if="isShowMore && count > 1">
              {{ item.totalCount }} {{ item.countUnit }}
              <!-- {{ item.count * count }} -->
            </span>
          </div>
          <div class="computation__item-volume">
            <b>{{ item.countPackage }} {{ item.countPackageUnit }}</b> <br />
            <span v-if="isShowMore && count > 1">
              <!-- {{ Math.round((item.volume * count).toFixed(3)) }} -->
              {{ item.totalCountPackage }} {{ item.countPackageUnit }}
              <!-- {{ item.countUnit }} -->
            </span>
          </div>
          <div class="computation__item-eye unprint">
            <tippy placement="left" theme="light" animation="shift-toward" arrow>
              <template #trigger>
                <div class="trigger">
                  <img src="@/assets/img/eye--table.svg" alt="eye" />
                </div>
              </template>
              <span class="tooltip-plate">
                <img :src="item.src" alt="" class="eye-popover__img" />
              </span>
            </tippy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getFormattedWord, detailsTables } from '@/utils'
import Toggler from '@/components/dump/Toggler.vue'
import { getMaterialsUnits } from '@/components/helper'

export default {
  props: {
    count: Number
  },
  components: { Toggler },
  methods: {
    ...mapMutations({
      updateExpandStatus: 'UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS',
      setUnit: 'UPDATE_UNIT'
    }),
    ...mapActions({
      resetUnit: 'resetUnit'
    }),
    updateUnit(evt) {
      this.setUnit({ index: this.slopeId, unit: evt.value })
      this.$emit('requestNewResults')
    }
  },
  computed: {
    ...mapGetters({
      allMaterials: 'allMaterials',
      allSlopes: 'allSlopes',
      result: 'getResult'
    }),
    unit() {
      return this.slopeFromState?.unit
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    computation() {
      return this.result.sections[this.slopeId][detailsTables.computation].data
    },
    isShowMore() {
      return this.result.sections[this.slopeId][detailsTables.computation].isExpanded
    },
    computedIsShowMore: {
      get() {
        return this.isShowMore
      },
      set(status) {
        this.updateExpandStatus({
          sectorIndex: this.slopeId,
          table: detailsTables.computation,
          status
        })
      }
    },
    currentMaterial() {
      return this.allMaterials.find(m => m.id === this.slopeFromState.material)
    },
    word() {
      const words = this.$t('message.result.head.words').sectors
      return getFormattedWord(words, this.count)
    },
    units() {
      return getMaterialsUnits(this.$i18n.messages[this.$i18n.locale].message)
    }
  }
}
</script>

<style lang="sass" scoped>
.computation-header
  display: flex
  +media((align-items: (320: flex-start, 768: center)))
  +media((flex-direction: (320: column, 768: row)))
  +media((justify-content: (320: flex-start, 768: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(23), 992: rem(68) rem(-15) rem(34))))
  +media((padding: (320: 0 rem(25), 992: 0)))
  .slopePage
    &__title
      padding: 0
      +media((margin-bottom: (320: rem(15), 768: 0)))
      +media((font-size: (320: rem(18), 768: rem(24))))
.detailsPage
  .computation
    border-radius: rem(4) rem(4) 0 0
    color: $black
    overflow-y: auto
    display: grid
    &__head
      background: $semi-white
      display: grid
      // grid-template-columns: 4fr 4fr 4fr 4fr 5fr 3fr
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      $t-xl: 6fr 10fr 8fr 4fr 5fr 5fr 4fr
      $t-l: 7fr 8fr 6fr 4fr 6fr 5fr 5fr
      $t-m: rem(200) rem(100) rem(60) rem(80) rem(80) rem(60)
      $t-s: rem(100) rem(100) rem(60) rem(80) rem(80) rem(60)
      +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
      +media((padding: (320: 0 rem(24), 768: 0)))
      &-title
        min-height: rem(60)
        display: flex
        flex-direction: column
        justify-content: center
        @extend .common__title-result
        text-align: left
        span
          margin-top: rem(6)
          font-weight: normal
          font-size: rem(12)
          line-height: 100%
        &:first-child
          +media((grid-column-start: (768: 2)))
    &__body
      width: 100%
      display: grid
      margin-top: rem(1)
      grid-template-columns: 1fr
      grid-template-rows: 1fr
      grid-row-gap: rem(1)
    &__item
      text-align: left
      background: $white
      align-items: center
      +media((padding: (320: 0 rem(25), 768: 0)))
      // grid-template-columns: repeat(6, 1fr)
      column-gap: calc(100% / 48)
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      $t-xl: 6fr 10fr 8fr 4fr 5fr 5fr 4fr
      $t-l: 7fr 8fr 6fr 4fr 6fr 5fr 5fr
      $t-m: rem(200) rem(100) rem(60) rem(80) rem(80) rem(60)
      $t-s: rem(100) rem(100) rem(60) rem(80) rem(80) rem(60)
      +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
      width: 100%
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: rem(17)
      grid-row-gap: rem(1)
      min-height: rem(68)
      display: grid
      &-img
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        +media((display: (320: none, 768: flex)))
        img
          width: rem(92)
          max-width: 100%
      &-volume, &-count
        span
          margin-top: rem(6)
          display: block
      &-eye
        line-height: 0
        .trigger
          height: rem(36)
          width: rem(36)
          border-radius: rem(4)
          transition: .5s
          display: flex
          justify-content: center
          align-items: center
          cursor: help

          img
            transition: .5s
            filter: invert(80%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(101%) contrast(93%)
          @media(any-hover: hover)
            &:hover
              background: $main
              opacity: $hover-opacity
              img
                filter: invert(1)
  .toggler__box
    display: flex
    flex-direction: row
    align-items: center
    .showMore
      &__text
        font-style: normal
        font-weight: 500
        padding-right: rem(20)
        text-align: left
        +media((font-size: (320: rem(12), 992: rem(14))))
        +media((line-height: (320: rem(20), 992: rem(22))))
    .checkbox-toggler
      .checkbox-track
        box-shadow: inset 0 rem(2) rem(4) rgba(153, 153, 153, 0.5)
.eye-popover
  &__img
    max-width: rem(175)
</style>

<template>
  <div class="container">
    <div class="row align-items-sm-center justify-content-between mt-34">
      <div class="slopePage__title">
        <b>{{ $t('message.details.fastener.title') }}</b>
        <tippy
          interactive
          placement="top"
          animation="shift-toward"
          arrow="false"
          :maxWidth="240"
          v-if="!fasteners.length"
        >
          <template #trigger>
            <div class="title-tooltip">
              <img src="@/assets/img/tooltip-icon.svg" alt="?" />
            </div>
          </template>
          <div class="simple-tooltip__text">{{ $t('message.details.fastener.tooltip') }}</div>
        </tippy>
      </div>
      <div class="toggler__box unprint" v-if="count > 1 && fasteners.length !== 0">
        <span class="showMore__text">
          {{ $t('message.details.fastener.showMore') }} ({{ count }})
        </span>
        <div class="fixtureCalculation__toggle">
          <div class="wrapper">
            <span class="checkbox-toggler">
              <span class="checkbox-track"></span>
              <input type="checkbox" v-model="computedIsShowMore" />
              <span class="toggler"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="fasteners-computation" v-if="!!fasteners.length">
      <div
        class="fasteners-computation__head"
        :class="{ 'fasteners-computation__head--extend': isShowMore && count > 1 }"
      >
        <div class="title">{{ $t('message.details.fastener.table.name') }}</div>
        <div class="title">{{ $t('message.details.fastener.table.ekn') }}</div>
        <div class="title">
          {{ $t('message.details.fastener.table.count') }} <br />
          <span>{{ $t('message.details.fastener.table.computation') }}</span>
        </div>
        <div class="title" v-if="isShowMore && count > 1">
          <span>{{ count }} {{ word }}</span>
        </div>
        <div class="title">
          {{ $t('message.details.fastener.table.count') }} <br />
          <span>{{ $t('message.details.fastener.table.package') }}</span>
        </div>
        <div class="title" v-if="isShowMore && count > 1">
          <span>{{ count }} {{ word }}</span>
        </div>
      </div>
      <div
        class="fasteners-computation__body"
        v-if="true"
        :class="{ 'fasteners-computation__body--extend': isShowMore && count > 1 }"
      >
        <div class="item" v-for="item in filteredFasteners" :key="item.id">
          <div class="item__img">
            <img :src="item.src" :alt="item.name" />
          </div>
          <div class="item__name">
            {{ item.name }}
          </div>
          <div class="item__ekn">{{ item.sku_hidden ? '' : item.sku }}</div>
          <div class="item__count">{{ item.count }} {{ $t('message.units.pc') }}.</div>
          <div class="item__count--additional" v-if="isShowMore && count > 1">
            {{ item.totalCount }} {{ $t('message.units.pc') }}.
          </div>
          <div class="item__count">{{ item.countPackage }} {{ $t('message.units.pc') }}.</div>
          <div class="item__count--additional" v-if="isShowMore && count > 1">
            {{ item.totalCountPackage }} {{ $t('message.units.pc') }}.
          </div>
        </div>
      </div>
      <table-show-remaining-button
        :is-show-remaining="isShowRemaining"
        v-if="isShowRemainingBtn"
        @toggleRemaining="isShowRemaining = !isShowRemaining"
      >
        {{ showRemainingText }}
      </table-show-remaining-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { detailsTables, getFormattedWord } from '@/utils'
import TableShowRemainingButton from '@/components/dump/TableShowRemainingButton'
const displayCount = 18

export default {
  props: {
    count: Number,
    isFastenersNeed: Boolean
  },
  data: () => ({
    isShowRemaining: false
  }),
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS: 'UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS'
    })
  },
  components: { TableShowRemainingButton },
  computed: {
    sortedFasteners() {
      return [...this.fasteners].sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = -1
        } else if (nameA < nameB) {
          comparison = 1
        }
        return comparison
      })
    },
    filteredFasteners() {
      return this.isShowRemaining ? this.fasteners : this.fasteners.slice(0, displayCount)
      // return this.isShowRemaining ? this.sortedFasteners : this.sortedFasteners.slice(0, 18);
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    result() {
      return this.$store.getters.getResult
    },
    fasteners() {
      return this.result.sections[this.slopeId][detailsTables.fasteners].data
    },
    isShowMore() {
      return this.result.sections[this.slopeId][detailsTables.fasteners].isExpanded
    },
    computedIsShowMore: {
      get() {
        return this.isShowMore
      },
      set(status) {
        this.UPDATE_SECTOR_RESULTS_TABLE_EXPAND_STATUS({
          sectorIndex: this.slopeId,
          table: detailsTables.fasteners,
          status
        })
      }
    },
    word() {
      const words = this.$t('message.result.head.words').sectors
      return getFormattedWord(words, this.count)
    },
    showRemainingText() {
      return this.isShowRemaining
        ? this.$t('message.details.fastener.hide')
        : `${this.$t('message.details.fastener.show')} ${this.fasteners.length}`
    },
    isShowRemainingBtn() {
      return this.fasteners.length > displayCount
    }
  }
}
</script>

<style lang="sass" scoped>
.detailsPage
  .mt-34
    margin-top: rem(34)
  .slopePage
    &__title
      +media((font-size: (320: rem(18), 992: rem(24))))
      +media((line-height: (320: rem(26), 992: rem(29))))
      +media((padding: (320: rem(25) rem(24) rem(23), 992: rem(36) 0)))
      display: flex
      align-items: center
      .title-tooltip
        margin-left: rem(10)
        display: flex
        align-items: center
  .toggler__box
    display: flex
    flex-direction: row
    align-items: center
    +media((padding-right: (320: rem(27), 768: rem(3))))
    +media((padding-bottom: (320: rem(28), 637: rem(0))))
    .showMore
      &__text
        font-style: normal
        font-weight: 500
        padding-right: rem(20)
        text-align: left
        +media((padding-left: (320: rem(24), 992: 0)))
        +media((font-size: (320: rem(12), 992: rem(14))))
        +media((line-height: (320: rem(20), 992: rem(22))))
    .checkbox-toggler
      .checkbox-track
        box-shadow: inset 0 rem(2) rem(4) rgba(153, 153, 153, 0.5)
  .fasteners-computation
    display: grid
    margin: 0 rem(-15)
    overflow-y: auto
    border-radius: rem(4) rem(4) 0 0
    color: $black
    &__head
      display: grid
      $t-xl: 5fr 11fr 8fr 8fr 8fr
      $t-xl-extend: 5fr 11fr 4fr 4fr 5fr 6fr 5fr
      $t-l: 5fr 10fr 9fr 9fr 9fr
      $t-l-extend: 5fr 10fr 4fr 5fr 5fr 6fr 5fr
      $t-s: rem(100) rem(160) repeat(3, rem(120))
      $t-s-extend: rem(100) rem(160) rem(60) repeat(4, rem(80))
      +media((grid-template-columns: (320: $t-s, 768: $t-l, 960: $t-xl)))
      &--extend
        +media((grid-template-columns: (320: $t-s-extend, 768: $t-l-extend, 960: $t-xl-extend)))
      +media((padding: (320: 0 rem(24), 768: 0)))
      +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
      background: $semi-white
      .title
        min-height: rem(60)
        display: flex
        flex-direction: column
        justify-content: center
        @extend .common__title-result
        text-align: left
        span
          margin-top: rem(6)
          font-weight: normal
          font-size: rem(12)
          line-height: 100%
        &:first-child
          grid-column-start: 2
    &__body
      width: 100%
      display: grid
      margin-top: rem(1)
      grid-template-columns: 1fr
      grid-template-rows: 1fr
      grid-row-gap: rem(1)

      .item
        text-align: left
        background: $white
        align-items: center
        +media((padding: (320: 0 rem(25), 768: 0)))
        // grid-template-columns: repeat(6, 1fr)
        column-gap: calc(100% / 48)
        +media((column-gap: (320: rem(24), 768: calc(100% / 48))))
        $t-xl: 5fr 11fr 8fr 8fr 8fr
        $t-l: 5fr 10fr 9fr 9fr 9fr
        $t-s: rem(100) rem(160) repeat(3, rem(120))
        +media((grid-template-columns: (320: $t-s, 768: $t-l, 960: $t-xl)))
        width: 100%
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(17)
        grid-row-gap: rem(1)
        min-height: rem(34)
        display: grid
        &__name
          padding: rem(10) 0
        &__img
          display: flex
          flex-direction: row
          justify-content: center
          align-items: center
          img
            width: rem(92)
            max-width: 100%
        &__count
          &--additional
            color: rgba($black, .5)
      &--extend
        .item
          $t-xl-extend: 5fr 11fr 4fr 4fr 5fr 6fr 5fr
          $t-s-extend: rem(100) rem(160) rem(60) repeat(4, rem(80))
          $t-l-extend: 5fr 10fr 4fr 5fr 5fr 6fr 5fr
          +media((grid-template-columns: (320: $t-s-extend, 768: $t-l-extend, 960: $t-xl-extend)))
</style>

<template>
  <div
    class="row-tooltip"
    :class="{
      'row-tooltip--simplified': !slopeFromState.fixtureCalculation.need
    }"
  >
    <div class="row-tooltip__info">
      <div class="text">
        <span class="text__name"> {{ $t('message.details.plateLayout.row') }} {{ rowId }} </span>
        <span class="text__info">
          {{ currentPlateScheme }}
        </span>
      </div>
      <div class="img" v-if="slopeFromState.fixtureCalculation.need">
        <span class="img__text">{{ currentRowHeight.max }}<br />{{ $t('message.units.mm') }}</span>
        <img :src="brickImg" alt="brick" />
        <span class="img__text">{{ currentRowHeight.max }}<br />{{ $t('message.units.mm') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'RowTooltip',
  props: {
    sectorIndex: {
      type: String,
      default: '0'
    },
    rowId: {
      type: Number,
      default: 0,
      required: true
    },
    layersCount: {
      type: Number,
      default: 1,
      required: true
    },
    plateHeight: {
      type: Number,
      default: 0,
      required: true
    },
    plateWidth: {
      type: Number,
      default: 0,
      required: true
    },
    materialAngle: {
      type: Number,
      default: 0,
      required: true
    },
    fixtureCalculationThickness: {
      type: Number,
      default: 0,
      required: true
    },
    backEndRowsCount: {
      type: Number,
      default: 1,
      required: true
    },
    hKr: {
      type: Number,
      default: 0,
      required: true
    },
    qKr: {
      type: Number,
      default: 0,
      required: true
    },
    y1: {
      type: Number,
      default: 0,
      required: true
    },
    y2: {
      type: Number,
      default: 0,
      required: true
    }
  },
  methods: {},
  computed: {
    ...mapState({
      result: state => state.result
    }),
    currentRowHeight() {
      return this.result.sections[this.sectorIndex].rowsHeight[this.rowId] ?? 0
    },
    currentPlateScheme() {
      return this.result.sections[this.sectorIndex].plateLayout[this.rowId]
    },
    brickImg() {
      if (this.plateWidth > this.plateHeight) {
        return this.isPir
          ? require('@/assets/img/pir-brick-horizontal.svg')
          : require('@/assets/img/brick-horizontal.svg')
      } else if (this.plateWidth < this.plateHeight) {
        return this.isPir
          ? require('@/assets/img/pir-brick-vertical.svg')
          : require('@/assets/img/brick-vertical.svg')
      } else {
        return require('@/assets/img/brick-rect.svg')
      }
    },
    isPir() {
      return this.allMaterials.find(p => p.id === this.slopeFromState.material).type === 'LOGICPIR'
    },
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    allMaterials() {
      return this.$store.getters.allMaterials
    },
    thickness() {
      return this.slopeFromState.fixtureCalculation.need ? this.fixtureCalculationThickness : 0
    },
    result() {
      return this.$store.getters.getResult
    },
    section() {
      return this.result.sections[this.slopeId]
    },
    maxFastener() {
      return Math.max.apply(
        null,
        this.section.fasteners_group.data.map(a => a.thickness)
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.row-tooltip
  border-radius: rem(4)
  width: rem(236)
  min-height: rem(111)
  &--simplified
    min-height: unset
    .row-tooltip
      &__info
        padding: rem(12) rem(28)
        .text
          margin: 0
  &__info
    display: flex
    flex-direction: column
    justify-content: center
    padding: rem(12) rem(28) rem(26)
    .text
      text-align: center
      margin-bottom: rem(15)
      &__name, &__info
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(18)
      &__name
        color: $black
      &__info
        color: $main
    .img
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      &__text
        font-style: normal
        font-weight: 500
        font-size: rem(12)
        line-height: rem(14)
        color: $light_black
        text-align: center
        min-width: rem(45)
</style>

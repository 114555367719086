<template>
  <div class="container plateLayout__container">
    <div class="row plateLayout">
      <div class="plateLayout__title">
        {{ $t('message.details.plateLayout.title') }}<span class="title__name">: {{ slopeFromState.name }}</span>
      </div>
    </div>
    <button class="print unprint" @click="print">
      <span v-html="getIcon('print')"></span>
    </button>
    <div
      class="row slopePage__pane plateLayout dragscroll"
      :style="{ overflow: isDesktop ? 'hidden' : 'auto' }"
      ref="plateLayout"
    >
      <component
        :is="currentFigureComponent"
        :slopeParams="slopeFromState.slopeParams"
        :defaultSvgParams="defaultSvgParams"
        isPlate
        isDetails
        :zoom="zoom"
        :isOffsetValleyNeed="isValleyOffsetNeed"
        :rowId="rowId"
        @updateRowId="updateRowId"
        ref="svgComponent"
      >
      </component>
    </div>
    <div class="zoom__container">
      <input
        type="range"
        v-model.number="zoom"
        min="1"
        max="10"
        step="1"
        class="similar__box-inputs-range"
        :style="`--min: 1;--max: 10;--val: ${zoom};`"
        ref="inputRange"
      />
      <div class="zoom__container-text">
        {{ $t('message.details.plateLayout.zoom') }} <b v-if="zoom > 1">x{{ zoom }}</b>
      </div>
    </div>
    <PrintTable
      :materialAngle="materialAngle"
      :fixtureCalculationThickness="fixtureCalculationThickness"
      :hKr="hKr"
      :qKr="qKr"
      :y1="y1"
      :y2="y2"
      :backEndRowsCount="backEndRowsCount"
    />
    <tippy
      to="rowsForTooltip"
      followCursor="true"
      :hideOnClick="false"
      theme="light"
      placement="right"
      :key="`tippy--${slopeFromState.uid}`"
    >
      <row-tooltip
        :rowId="rowId"
        :sectorIndex="slopeId"
        :layersCount="slopeFromState.slopeLayersCount"
        :plateHeight="slopeFromState.materialSize.height"
        :plateWidth="slopeFromState.materialSize.width"
        :materialAngle="materialAngle"
        :fixtureCalculationThickness="fixtureCalculationThickness"
        :backEndRowsCount="backEndRowsCount"
        :hKr="hKr"
        :qKr="qKr"
        :y1="y1"
        :y2="y2"
      />
    </tippy>
  </div>
</template>
<script>
import device from 'current-device'
import { defaultSvgParams, formsComponents, checkIsOffsetValleyNeed } from '@/utils/drawing'
import dragscroll from 'dragscroll/dragscroll.js'
import PrintTable from '../PrintTable'
import RowTooltip from '@/components/smart/RowTooltip'
import IconMixin from '@/components/mixin/IconMixin'

export default {
  mixins: [IconMixin],
  props: {
    slope: Object,
    materialAngle: Number,
    fixtureCalculationThickness: Number,
    hKr: Number,
    qKr: Number,
    y1: Number,
    y2: Number,
    backEndRowsCount: Number
  },
  data() {
    return {
      zoom: 1,
      rowId: 0,
      isDesktop: true
    }
  },
  components: {
    RowTooltip,
    PrintTable
  },
  methods: {
    updateRowId(number) {
      this.rowId = number
    },
    styleInputRange() {
      document.documentElement.classList.add('js')
      this.$refs.inputRange.addEventListener('input', this.addStyleToRange, false)
    },
    addStyleToRange() {
      this.$refs.inputRange.style.setProperty('--val', +this.$refs.inputRange.value)
    },
    print() {
      window.print()
    }
  },
  watch: {
    zoom() {
      const { width, height } = defaultSvgParams
      const ratio = width / height
      const figureWidth = this.$refs.plateLayout.offsetWidth - 48
      const figureHeight = figureWidth / ratio
      if (window.screen.width <= 767) {
        this.$refs.plateLayout.scrollTo(
          (figureWidth * this.zoom) / 2 - figureWidth / 2,
          (figureHeight * this.zoom) / 2 - figureHeight / 2
        )
      } else {
        setTimeout(() => {
          this.$refs.plateLayout.scrollTo(
            (width * this.zoom) / 2 - width / 2,
            (height * this.zoom) / 2 - height / 2
          )
        })
      }
    }
  },
  mounted() {
    this.styleInputRange()
    dragscroll.reset()
    this.isDesktop = device.desktop()
  },
  computed: {
    slopeFromState() {
      return this.$store.getters.getSlope(this.slopeId)
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id.toString() : '0'
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    defaultSvgParams: () => defaultSvgParams,
    slopeParams() {
      const [paramsKit] = this.slopeFromState.slopeParams
      return paramsKit
    },
    isValleyOffsetNeed() {
      const { slopeFromState, slopeParams } = this
      return checkIsOffsetValleyNeed(slopeFromState, slopeParams)
    },
    currentFigureComponent() {
      return formsComponents[this.slopeFromState.slopeForm]
    }
  }
}
</script>

<style lang="sass">
.zoom
  &__container
    background: $white
    display: flex
    flex-direction: row
    align-items: center
    width: rem(280)
    right: rem(32)
    bottom: rem(290)
    transform-origin: right
    border-radius: rem(4)
    justify-content: center
    +media((transform: (320: rotate(0), 576: rotate(-90deg))))
    +media((bottom: (320: unset, 576: rem(290))))
    +media((right: (320: unset, 576: rem(32))))
    +media((position: (320: relative, 576: absolute)))
    +media((padding: (320: rem(10), 576: 0 rem(10))))
    +media((margin: (320: rem(20) auto, 576: 0)))
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(18)
      color: $tn_gray_plus
      +media((padding-left: (320: rem(20), 576: rem(4))))
      b
        padding-left: rem(4)
.detailsPage
  .plateLayout
    position: relative
    background: $white
    &__container
      position: relative
    &__title
      .title__name
        display: none
      @extend .common__title-layout
      +media((padding: (320: rem(43) rem(24) rem(23), 992: rem(36) 0)))
    .figure__wrapper
      margin: auto
      display: block
  .print
    position: absolute
    z-index: 2
    display: block
    background: none
    box-shadow: none
    outline: none
    border: none
    float: right
    padding: rem(10)
    background: $white
    right: rem(20)
    cursor: pointer
    +media((top: (320: rem(97), 576: rem(102), 992: rem(121))))
    +media((right: (320: rem(5), 576: rem(10), 992: rem(20))))
  .rtfy
    display: flex
    flex-direction: row
    justify-content: flex-end
    align-items: flex-end
    margin: auto
    clip-path: url(#figureMask)
    +media((display: (320: none, 768: flex)))
  .plates
    display: flex
    flex-direction: row
    align-items: center
    &__column
      line-height: 0
      &:hover
        .plates__column-item
          background: #f7b4b7
          border-color: $main
      &-item
        background: $semi-white
        border: rem(1) solid $tn_gray_plus
        transition: .5s
</style>
